import { Box, Center, Grid, GridItem, Image, VStack } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import React, { FC } from "react";
import * as Texts from "src/shared/components/Texts";
import * as consts from "src/shared/consts";

const PartnerLogo = ({ src, alt }: { src: string; alt: string }) => (
  <GridItem w="100%" h="70px" display="flex" justifyContent="center" alignItems="center">
    <Image src={src} alt={alt} height="100%" objectFit="contain" />
  </GridItem>
);

export const Partners: FC = () => {
  const { t } = useTranslation();
  const partners = [
    { src: "/img/partners/instarisa.png", alt: "Instarisa" },
    { src: "/img/partners/biotech.png", alt: "Biotech Dental" },
    { src: "/img/partners/hybridge.png", alt: "Hybridge" },
  ];

  return (
    <Box
      id="tech"
      px={{ base: "20px", sm: "100px", md: "200px" }}
      mb="165px"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <VStack textAlign="center">
        <Texts.NormalCaps color={consts.COLOR_RED}>{t("home:partnersHead")}</Texts.NormalCaps>
        <Center>
          <Grid templateColumns="repeat(3, 1fr)" gap={20} mt={5}>
            {partners.map((partner, index) => (
              <PartnerLogo key={index} src={partner.src} alt={partner.alt} />
            ))}
          </Grid>
        </Center>
      </VStack>
    </Box>
  );
};
