import React from "react";
import useTranslation from "next-translate/useTranslation";
import { Box } from "@chakra-ui/react";
import { ButtonLink } from "src/shared/components/Button";
import { UDINI_CONTACT_ROUTE } from "src/utils/constants/routes";

export const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <Box display={"flex"} justifyContent={"center"} py={"50px"}>
      <ButtonLink href={UDINI_CONTACT_ROUTE} variant={"cta"} w={"320px"}>
        {t("shared:buttonContactUs")}
      </ButtonLink>
    </Box>
  );
};
