import React, { FC } from "react";
import NextImage from "next/image";
import useTranslation from "next-translate/useTranslation";
import { Box } from "@chakra-ui/react";
import * as Texts from "src/shared/components/Texts";
import { ContactUs } from "../ContactUs";

export const Hero: FC = () => {
  const { t } = useTranslation();
  return (
    <Box position={"relative"}>
      <Box position={"absolute"} inset={0} top={"80px"} overflow={"hidden"}>
        <Box
          position={"absolute"}
          left={"50%"}
          w={{ base: "150%", md: "100%" }}
          maxW={"1162px"}
          transform={"translate(-50%, 0%)"}
        >
          <NextImage src={"/svg/hero-bg.svg"} alt={"Hero bg"} width="1162px" height="551px" />
        </Box>
        <Box
          position={"absolute"}
          left={"50%"}
          w={{ base: "150%", md: "100%" }}
          maxW={"1162px"}
          transform={"translate(-50%, 0%)"}
          mixBlendMode={"overlay"}
        >
          <NextImage src={"/svg/hero-fg.svg"} alt={"Hero fg"} width="1162px" height="551px" />
        </Box>
      </Box>
      <Box
        position={"relative"}
        px={"15px"}
        pt={{ base: "300px", sm: "500px" }}
        pb={{ base: "80px", md: "100px" }}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Texts.H1 mb={"20px"} maxW={"780px"} textAlign={"center"}>
          {t("home:heroTitle")}
        </Texts.H1>
        <Texts.Large maxW={"880px"} textAlign={"center"}>
          {t("home:heroSubtitle")}
        </Texts.Large>
        <ContactUs />
      </Box>
    </Box>
  );
};
