import React, { FC, ReactNode } from "react";
import { Box } from "@chakra-ui/react";
import * as Texts from "src/shared/components/Texts";

export interface GotoButtonProps {
  children?: ReactNode;
  onClick?: () => void;
}

export const GotoButton: FC<GotoButtonProps> = (props) => {
  return (
    <Box
      h={"67px"}
      px={"30px"}
      display={"flex"}
      alignItems={"center"}
      bg={"rgba(255, 255, 255, 0.8)"}
      outline={"1px solid #BBD1F2"}
      borderRadius={"2px"}
      boxShadow={"0px 20px 32px rgba(70, 25, 164, 0.05)"}
      cursor={"pointer"}
      _hover={{
        color: "white",
        bg: "linear-gradient(90deg, #FF2D55 0%, #28D4FF 100%)",
        outline: "none",
      }}
      onClick={props.onClick}
    >
      <Box flex={1}>
        <Texts.NormalCaps lineHeight={"unset"}>{props.children}</Texts.NormalCaps>
      </Box>
      <Box flex={"none"} h={"16px"} ml={"10px"}>
        <svg viewBox="0 0 10 15" height={"100%"}>
          <path
            d="M9.65137 7.3215C9.65137 7.03585 9.53711 6.79102 9.29101 6.57882L2.45312 0.359933C2.25977 0.180385 2.01367 0.0824496 1.72363 0.0824496C1.14355 0.0824497 0.677734 0.506836 0.677734 1.05364C0.677734 1.32296 0.800781 1.55964 0.99414 1.74735L7.15527 7.3215L0.994141 12.8956C0.800782 13.0834 0.677735 13.3282 0.677735 13.5894C0.677735 14.1362 1.14356 14.5605 1.72363 14.5605C2.01367 14.5605 2.25977 14.4626 2.45313 14.2831L9.29102 8.07234C9.53711 7.85198 9.65137 7.60714 9.65137 7.3215Z"
            fill="currentColor"
          />
        </svg>
      </Box>
    </Box>
  );
};
