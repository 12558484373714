import React, { FC } from "react";
import useTranslation from "next-translate/useTranslation";
import { Box, VStack } from "@chakra-ui/react";
import { GotoButton } from "src/shared/components/GotoButton";
import * as Texts from "src/shared/components/Texts";
import * as consts from "src/shared/consts";
import * as routes from "src/utils/constants/routes";

export const Tech: FC = () => {
  const { t, lang } = useTranslation();
  return (
    <Box
      id={"tech"}
      px={{ base: "20px", sm: "100px", md: "200px" }}
      mb={"165px"}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Box maxW={"1400px"}>
        <VStack mb={"65px"} textAlign="center">
          <Texts.NormalCaps color={consts.COLOR_RED}>{t("home:techHead")}</Texts.NormalCaps>
          <Texts.H1 mb={"5px"}>{t("home:techTitle")}</Texts.H1>
          <Texts.Large maxW={"570px"}>
            {t("home:techSubtitle1")}
            <br />
            <br />
            {t("home:techSubtitle2")}
          </Texts.Large>
        </VStack>

        <Box margin={"auto"} maxW={"566px"}>
          <Texts.H4 marginBottom={"20px"}>{t("home:techSection1")}</Texts.H4>
          <VStack flex={1} spacing={"25px"} align={"stretch"}>
            <GotoButton
              onClick={() => {
                routes.pushRoute(routes.AIAAS_2D_SIMULATION_ROUTE, lang);
              }}
            >
              {t("home:techButton2dSimulation")}
            </GotoButton>
            <GotoButton
              onClick={() => {
                routes.pushRoute(routes.AIAAS_CASE_DIFFICULTY_ESTIMATION_FROM_STL_ROUTE, lang);
              }}
            >
              {t("home:techButtonCaseDifficulty")}
            </GotoButton>
          </VStack>
          <Texts.H4 marginTop={"40px"} marginBottom={"20px"}>
            {t("home:techSection2")}
          </Texts.H4>
          <VStack flex={1} spacing={"25px"} align={"stretch"}>
            <GotoButton
              onClick={() => {
                routes.pushRoute(routes.AIAAS_2D_SMILE_DESGN_ROUTE, lang);
              }}
            >
              {t("home:techButton2dSmileDesign")}
            </GotoButton>
          </VStack>
          <Texts.H4 marginTop={"40px"} marginBottom={"20px"}>
            {t("home:techSection3")}
          </Texts.H4>
          <VStack flex={1} spacing={"25px"} align={"stretch"}>
            <GotoButton
              onClick={() => {
                routes.pushRoute(routes.AIAAS_TEETH_SEGMENTATION_ROUTE, lang);
              }}
            >
              {t("home:techButtonTeethSegmentation")}
            </GotoButton>
            <GotoButton
              onClick={() => {
                routes.pushRoute(routes.AIAAS_PHOTO_ALIGNMENT_ROUTE, lang);
              }}
            >
              {t("home:techButtonPhotoAlignment")}
            </GotoButton>
            <GotoButton
              onClick={() => {
                routes.pushRoute(routes.AIAAS_FACE_SCAN_ALIGNMENT_ROUTE, lang);
              }}
            >
              {t("home:techButtonFaceScanAlignment")}
            </GotoButton>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
};
