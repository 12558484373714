import React, { FC } from "react";
import useTranslation from "next-translate/useTranslation";
import { Box, Stack, VStack } from "@chakra-ui/react";
import * as Texts from "src/shared/components/Texts";
import * as consts from "src/shared/consts";
import { ProcessItem } from "../ProcessItem";
import { ContactUs } from "../ContactUs";

export const Process: FC = () => {
  const { t } = useTranslation();
  return (
    <Box px={{ base: "20px", sm: "100px", md: "200px" }} mb={"100px"}>
      <VStack align={"center"} mb={"70px"}>
        <Texts.NormalCaps textAlign={"center"} color={consts.COLOR_RED}>
          {t("home:processHead")}
        </Texts.NormalCaps>
        <Texts.H1 textAlign={"center"} color={consts.COLOR_RED} mb={"10px"}>
          {t("home:processTitle")}
        </Texts.H1>
        <Texts.Large maxW={"500px"} textAlign={"center"}>
          {t("home:processSubtitle")}
        </Texts.Large>
      </VStack>
      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: "40px", md: "200px" }}
        align={{ base: "center", md: "stretch" }}
        justify={"center"}
        mb={"50px"}
      >
        <ProcessItem
          title={t("home:processWebApiTitle")}
          description={t("home:processWebApiDescription")}
          image={"/svg/process-web-api.svg"}
          color={consts.COLOR_PURPLE}
        />
        <Box display={{ base: "none", md: "reset" }} w={"1px"} borderRight={"1px dashed #BDBDBD"}></Box>
        <ProcessItem
          title={t("home:processCustomDevelopmentTitle")}
          description={t("home:processCustomDevelopmentDescription")}
          image={"/svg/process-custom-development.svg"}
          color={consts.COLOR_BLUE}
        />
      </Stack>
      <ContactUs />
    </Box>
  );
};
