import React, { FC } from "react";
import { Box, Image } from "@chakra-ui/react";
import * as Texts from "src/shared/components/Texts";

export interface ProcessItemProps {
  title: string;
  description: string;
  image: string;
  color: string;
}

export const ProcessItem: FC<ProcessItemProps> = (props) => {
  return (
    <Box w={"300px"} display={"flex"} flexDirection={"column"} alignItems={"center"}>
      <Box
        w={"160px"}
        h={"200px"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        py={"40px"}
        bg={"rgba(255, 255, 255, 0.8)"}
        border={"2px solid white"}
        boxShadow={"0px 28px 32px rgba(70, 25, 164, 0.18)"}
        borderRadius={"4px"}
        mb={"60px"}
      >
        <Image src={props.image} />
      </Box>
      <Texts.Large textAlign={"center"} color={props.color} mb={"10px"}>
        <strong>{props.title}</strong>
      </Texts.Large>
      <Texts.Large textAlign={"center"} color={props.color}>
        {props.description}
      </Texts.Large>
    </Box>
  );
};
