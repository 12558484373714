import React from "react";
import Head from "next/head";
import useTranslation from "next-translate/useTranslation";
import { Box } from "@chakra-ui/react";
import { Layout } from "../../shared/components/Layout";
import { Hero } from "./components/Sections/Hero";
import { Tech } from "./components/Sections/Tech";
import { Process } from "./components/Sections/Process";
import { Partners } from "./components/Sections/Partners";

export const Home = () => {
  const { t } = useTranslation();
  return (
    <>
      <Head>
        <title>{t("home:pageTitle")}</title>
      </Head>
      <Layout headerContent={<Hero />}>
        <Box>
          <Partners />
          <Tech />
          <Process />
        </Box>
      </Layout>
    </>
  );
};
